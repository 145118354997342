import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/bg-404.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Container/Container.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/HeaderBar/HeaderBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/PolyComponent/PolyComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/SearchModule/SearchModule.tsx");
